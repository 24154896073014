import React from "react"


class Person extends React.Component {
    constructor(props) {
        super(props);
        this.state = {contacts: []}
    }

    componentDidMount() {
        fetch('https://randomuser.me/api/?results=5')
        .then(result => result.json())
        .then(data => this.setState({contacts: data.results}));
    }
    render(){
        return (
            <div>
                <ul>
                {
                    this.state.contacts.map( (contact) => (
                        <li key={contact.login.uuid}>{contact.name.first} {contact.name.last}</li>
                    ))
                }
                </ul>
            </div>
        );
    }
}

export default Person