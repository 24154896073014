import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Person from "../components/person"
import SEO from "../components/seo"
import Title from "../components/title"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Title />
    <div style={{ margin: `3rem auto`, maxWidth: 600 }}>
    <Person />
      </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default AboutPage
